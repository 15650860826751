import axios from 'axios'
import {
  contentType,
  debounce,
  requestTimeout,
  successCode,
  tokenName,
} from '@/config'
import store from '@/store'
import qs from 'qs'
import router from '@/router'
import { isArray } from '@/utils/validate'
import { message } from 'ant-design-vue'

let loadingInstance

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 處理code異常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  switch (code) {
    case 401:
      message.error(msg || '登入失效')
      store.dispatch('user/resetAll').catch(() => {})
      break
    case 403:
      router.push({ path: '/401' }).catch(() => {})
      break
    default:
      // message.error(msg || `後端接口${code}異常`)
      break
  }
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios請求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken'])
      config.headers[tokenName] = store.getters['user/accessToken']
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    )
      config.data = qs.stringify(config.data)
    if (debounce.some((item) => config.url.includes(item))) {
      // 這裡寫加載動畫
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @author chuzhixin 1204505056@qq.com
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) loadingInstance.close()

    const { data, config, status } = response
    const { message } = data

    // 操作正常Code数组
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]]
    // 是否操作正常
    if (codeVerificationArray.includes(status)) {
      return { data, status }
    } else {
      handleCode(status, message)
      return Promise.reject(
        'vue-admin-beautiful請求異常攔截:' +
          JSON.stringify({ url: config.url, message }) || 'Error'
      )
    }
  },
  (error) => {
    if (loadingInstance) loadingInstance.close()
    const { response, message } = error
    if (error.response && error.response.data) {
      const { status, data } = response
      handleCode(status, data.msg || message)
      return { success: false, ...response }
      // return Promise.reject(error)
    } else {
      let { message } = error
      if (message === 'Network Error') {
        message = '後端接連接異常'
      }
      if (message.includes('timeout')) {
        message = '後端接口請求超時'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '後端接口' + code + '異常'
      }
      message.error(message || `後端接口未知異常`)
      return Promise.reject(error)
    }
  }
)

export default instance
