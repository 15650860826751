/**
 * @author cwhuang
 * @description 儲存已操作的table行為(ex.sorter、filter)
 */

const state = {
  tableVar: [],
}
const getters = {
  tableVar: (state) => state.tableVar,
}
const mutations = {
  /**
   * @author cwhuang
   * @description 設定排序資料
   * @param {*} state
   * @param {*} payload
   *  + `path` table名稱
   *  + `sortedInfo` 排序條件
   */
  setSortedInfo(state, payload) {
    if (state.tableVar.findIndex((i) => i.path == payload.path) == -1) {
      state.tableVar.push({
        path: payload.path,
        sortedInfo: payload.sortedInfo,
        filteredInfo: null,
      })
    } else {
      state.tableVar.find((i) => i.path == payload.path).sortedInfo =
        payload.sortedInfo
    }
  },
  /**
   * @author cwhuang
   * @description 設定篩選資料
   * @param {*} state
   * @param {*} payload
   *  + `path` table名稱
   *  + `filteredInfo` 篩選條件
   */
  setfilteredInfo(state, payload) {
    if (state.tableVar.findIndex((i) => i.path == payload.path) == -1) {
      state.tableVar.push({
        path: payload.path,
        filteredInfo: payload.filteredInfo,
        sortedInfo: null,
      })
    } else {
      state.tableVar.find((i) => i.path == payload.path).filteredInfo =
        payload.filteredInfo
    }
  },
}
const actions = {
  setSortedInfo({ commit }, params) {
    commit('setSortedInfo', params)
  },
  setfilteredInfo({ commit }, params) {
    commit('setfilteredInfo', params)
  },
}
export default { state, getters, mutations, actions }
