/**
 * @author cwhuang
 * @description 健康方案相關
 */
import { healthCases } from '@/api/healthy'

const state = {
  healthCases: [],
}
const getters = {
  healthCases: (state) => state.healthCases,
}
const mutations = {
  /**
   * @author cwhuang
   * @description 取得健康方案
   * @param {*} state
   * @param {*} payload
   */
  setHealthCases(state, payload) {
    state.healthCases = payload
  },
}
const actions = {
  /**
   * @author cwhuang
   * @description 取得健康方案
   * @param {*} { commit }
   */
  async getHealthCases({ commit }) {
    const { data } = await healthCases()
    commit('setHealthCases', data.data)
  },
}
export default { state, getters, mutations, actions }
