/**
 * @author cwhuang
 * @description 會員相關
 */
import { search } from '@/api/cust'

const state = {
  isLoading: false,
  filter: {
    searchItem: 'phone',
  },
  searchResult: [],
  custInfo: {},
  custsDetail: [], // 會員詳細資料(搜尋後存放，避免重複查詢)
}
const getters = {
  isLoading: (state) => state.isLoading,
  filter: (state) => state.filter,
  searchResult: (state) => state.searchResult,
  custInfo: (state) => state.custInfo,
}
const mutations = {
  /**
   * @author cwhuang
   * @description 設置searchResult
   * @param {*} state
   * @param {*} payload
   */
  setSearchResult(state, payload) {
    state.searchResult = payload
  },
  /**
   * @author cwhuang
   * @description 設置setFilter
   * @param {*} state
   * @param {*} payload
   */
  setFilter(state, payload) {
    state.filter = payload
  },
  /**
   * @author cwhuang
   * @description 設置custInfo
   * @param {*} state
   * @param {*} payload
   */
  setCustInfo(state, payload) {
    state.custInfo = payload
  },
  /**
   * @author cwhuang
   * @description 存入custsDetal
   * @param {*} state
   * @param {*} payload
   */
  setCustsDetail(state, payload) {
    state.custsDetail.push(payload)
  },
}
const actions = {
  /**
   * @author cwhuang
   * @description 搜尋會員
   * @param {*} { commit, state }
   */
  async search({ commit, state }) {
    if (['cardID', 'memberID'].includes(state.filter.searchItem)) {
      const { data } = await search(state.filter)
      if (data.found) {
        commit('setSearchResult', [{ ...data }])
      }
    } else {
      const { data } = await search(state.filter)
      commit('setSearchResult', data.members)
    }
  },

  /**
   * @author cwhuang
   * @description 設置搜尋條件
   * @param {*} { commit }
   * @param {*} rule
   */
  setFilter({ commit }, rule) {
    commit('setFilter', rule)
  },

  /**
   * @author cwhuang
   * @description 取得會員資料
   * @param {*} { commit, state }
   */
  async getCustInfo({ commit, state }, params) {
    const hasData = state.custsDetail.find((item) => {
      return item.memberId == params.memberID
    })
    if (!hasData) {
      const { data } = await search({
        searchItem: 'memberID',
        searchValue: params.memberID,
      })
      commit('setCustsDetail', data)
      commit('setCustInfo', data)
      return data
    } else {
      commit('setCustInfo', hasData)
      return hasData
    }
  },
}
export default { state, getters, mutations, actions }
