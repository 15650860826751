import request from '@/utils/request'

export async function getStore(params) {
  return request({
    url: '/stores',
    method: 'get',
    params,
  })
}

export async function getStation() {
  return request({
    url: '/hubs',
    method: 'get',
  })
}

export async function setStation(data) {
  return request({
    url: '/hubs',
    method: 'patch',
    data,
  })
}

// 刪除小站
export async function delStation(data) {
  return request({
    url: '/hubs',
    method: 'delete',
    data,
  })
}

// 發佈註銷
export async function logoffStation(data) {
  return request({
    url: '/hubs/logingoff',
    method: 'post',
    data,
  })
}
