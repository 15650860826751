import request from '@/utils/request'
import { tokenName } from '@/config'

export async function login(data) {
  return request({
    url: '/yclogin',
    method: 'post',
    data,
  })
}

export async function socialLogin(data) {
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken) {
  //此处为了兼容mock.js使用data传递accessToken，如果使用mock可以走headers
  return request({
    url: '/staffInfo',
    method: 'get',
    data: {
      [tokenName]: accessToken,
    },
  })
}

/**
 * 用員工ID或員工編號取得使用者資料
 * @param {*} params 搜尋條件
 * - staffId 使用者ID
 * - loginName 員工編號
 * @returns
 */
export function getUser(params) {
  return request({
    url: '/staves',
    method: 'get',
    params,
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post',
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}
