<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
        {{ username }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="logout">帳號登出</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
  import { recordRoute } from '@/config'
  import { DownOutlined } from '@ant-design/icons-vue'

  import { mapGetters } from 'vuex'
  import { versionNumber, buildNumber } from '@/config'

  export default {
    name: 'VabAvatar',
    components: { DownOutlined },
    data() {
      return {
        versionNumber,
        buildNumber,
      }
    },
    computed: {
      ...mapGetters({
        username: 'user/username',
      }),
    },
    methods: {
      async logout() {
        await this.$store.dispatch('user/logout')
        if (recordRoute) {
          const fullPath = this.$route.fullPath
          this.$router.push(`/login?redirect=${fullPath}`)
        } else {
          this.$router.push('/login')
        }
      },
    },
  }
</script>
<style lang="less">
  .vab-avatar {
    .ant-dropdown-link {
      display: block;
      min-height: 50px;
      cursor: pointer;
    }
  }
</style>
