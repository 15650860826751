/**
 * @author cwhuang
 * @description 門市相關
 */
import { getStore } from '@/api/store'

const state = {
  storeArea: {}, // 所有門市清單(依門市分區階層)
  userStoreArea: {}, // 使用者可視範圍門市清單(依門市分區階層)
  storeTree: {}, // 將門市資料做成樹狀節點
  checkedListTree: [],
}
const getters = {
  storeArea: (state) => state.storeArea,
  storeList: (state) => {
    const storeList = []
    state.storeArea.area.forEach((area) => {
      area.area_sub.forEach((areaSub) => {
        areaSub.store.forEach((store) => {
          storeList.push({
            ...store,
            area_id: area.area_id,
            area_name: area.area_name,
            area_sub_id: areaSub.area_sub_id,
            area_sub_name: areaSub.area_sub_name,
          })
        })
      })
    })
    return storeList.sort((a, b) => {
      return a.store_name > b.store_name ? 1 : -1
    })
  },
  userStoreArea: (state) => state.userStoreArea,
  storeTree: (state) => state.storeTree,
  // 取得所有節點的key與title(供後續搜尋用)
  storeTreeGenerateList: (state) => {
    const dataList = []
    const generateList = (data) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i]
        const key = node.key
        dataList.push({
          key,
          title: node.title,
          store_id: key.split('-').pop(),
        })

        if (node.children) {
          generateList(node.children)
        }
      }
    }
    generateList(state.storeTree)
    return dataList
  },
  checkedListTree: (state) =>
    state.checkedListTree.sort((a, b) => (a > b ? 1 : -1)),
}
const mutations = {
  /**
   * @author cwhuang
   * @description 設置storeArea
   * @param {*} state
   * @param {*} payload
   */
  setStoreArea(state, payload) {
    state.storeArea = payload
  },
  setUserStoreArea(state, payload) {
    state.userStoreArea = payload
  },
  setStoreTree(state, payload) {
    state.storeTree = payload
  },
  setCheckedListTree(state, payload) {
    state.checkedListTree = payload
  },
}
const actions = {
  /**
   * @author cwhuang
   * @description 取得門市清單
   * @param {*} { commit }
   */
  async getStore({ commit }) {
    const { data } = await getStore()
    commit('setStoreArea', { area: data.result.area })
    const storeTree = [
      {
        title: '全部門市',
        key: '0',
        children: data.result.area
          .filter((item) => item.area_name !== '網路')
          .map((area) => {
            return {
              title: area.area_name,
              key: `0-${area.area_id}`,
              children: area.area_sub.map((area_sub) => {
                return {
                  title: area_sub.area_sub_name,
                  key: `0-${area.area_id}-${area_sub.area_sub_id}`,
                  children: area_sub.store.map((store) => {
                    return {
                      title: store.store_name,
                      key: `0-${area.area_id}-${area_sub.area_sub_id}-${store.store_id}`,
                    }
                  }),
                }
              }),
            }
          }),
      },
    ]
    commit('setStoreTree', storeTree)
  },

  /**
   * @author cwhuang
   * @description 取得使用者可視範圍門市清單(依門市分區階層)
   * @param {*} { state, commit, rootState }
   */
  getUserStore({ state, commit, rootState }) {
    const storeArr = rootState.user.userInfo.branches.map((item) => item.id)
    let areaSubList = []
    let areaAreaList = []
    state.storeArea.area.forEach((area) => {
      area.area_sub.forEach((area_sub) => {
        const stores = area_sub.store.filter((store) => {
          return storeArr.includes(store.store_id)
        })
        if (stores.length > 0) {
          areaSubList.push({ ...area_sub, store: stores })
        }
      })
      if (areaSubList.length > 0) {
        areaAreaList.push({ ...area, area_sub: areaSubList })
      }
      areaSubList = []
    })
    commit('setUserStoreArea', { area: areaAreaList })
  },

  /**
   * @author cwhuang
   * @description 設置選取的門市(tree)
   */
  setCheckedListTree({ commit }, params) {
    commit('setCheckedListTree', params)
  },

  /**
   * @author cwhuang
   * @description 刪除節點
   */
  removeChecked({ commit, state }, key) {
    let checkedList = state.checkedListTree
    checkedList = checkedList
      .filter((item) => item != key)
      .filter((item) => item.indexOf(`${key}-`) != 0)
    commit('setCheckedListTree', checkedList)
  },
}
export default { state, getters, mutations, actions }
