<template>
  <svg
    class="svg-icon"
    aria-hidden="true"
    :style="{ width: customSize, height: customSize }"
  >
    <use :xlink:href="`#${iconName}`" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      iconName: {
        type: String,
        required: true,
      },
      customSize: {
        type: String,
        default: '1rem',
      },
    },
    setup() {
      // const widthd = ref(props.width)
      // return {
      //   width: '16px',
      // }
    },
  }
</script>

<style lang="less" scoped>
  .svg-icon {
    vertical-align: middle;

    fill: currentColor !important;
    overflow: hidden;
  }
</style>
