import request from '@/utils/request'

/**
 * 依會員查詢健康合約列表
 * @param {*} data
 * @returns
 */
export async function getContracts(data) {
  const searchParams = { memberID: data.memberID }

  if (typeof data.state !== 'undefined') {
    searchParams.state = data.state
  }

  // 時間區間
  if (typeof data.date !== 'undefined' && data.date.length > 0) {
    searchParams.startTime = new Date(data.date[0]).getTime()
    searchParams.endTime = new Date(data.date[1].endOf('day')).getTime()
  } else {
    searchParams.startTime = '1609430400000'
    searchParams.endTime = '4102329600000'
  }

  // 是否指定狀態
  if (typeof data.status !== 'undefined' && data.status != '') {
    searchParams.state = data.status
  }

  return request({
    url: '/contracts',
    method: 'get',
    params: { ...searchParams },
  })
}

export async function getCase(data) {
  return request({
    url: '/case/getCase',
    method: 'post',
    data,
  })
}

export async function getCaseDetail(params) {
  const { memberID } = params
  return request({
    url: `/case/getCaseDetail/${memberID}`,
    method: 'get',
  })
}

/**
 * 以會員編號取得合約商品使用紀錄
 * @param {*} data
 * @returns
 */
export async function getReceiveRecord(data) {
  const searchParams = { memberID: data.memberID }

  // 是否設定時間區間
  if (typeof data.date !== 'undefined' && data.date.length > 0) {
    searchParams.startTime = new Date(data.date[0]).getTime() / 1000
    searchParams.endTime = new Date(data.date[1].endOf('day')).getTime() / 1000
  } else {
    searchParams.startTime = '1609430400'
    searchParams.endTime = '4102329600'
  }

  return request({
    url: '/contractGoodsUsages/member/query',
    method: 'post',
    data: { ...searchParams },
  })
}

/**
 * 以合約編號取得合約商品使用紀錄
 * @param {*} data
 * @returns
 */
export async function getReceiveRecordByContractID(data) {
  const searchParams = { contractID: data.contractID }

  // 是否設定時間區間
  if (typeof data.date !== 'undefined' && data.date.length > 0) {
    searchParams.startTime = new Date(data.date[0]).getTime() / 1000
    searchParams.endTime = new Date(data.date[1].endOf('day')).getTime() / 1000
  } else {
    searchParams.startTime = '1609430400'
    searchParams.endTime = '4102329600'
  }

  return request({
    url: '/contractGoodsUsages/query',
    method: 'post',
    data: { ...searchParams },
  })
}

/**
 * 領用耗材
 * @param {*} data
 * @returns
 */
export async function setReceive(data) {
  return request({
    url: '/contractGoodsUsages',
    method: 'post',
    data,
  })
}

/**
 * 變更合約商品使用紀錄的狀態
 * @param {*} data
 * - id 合約ID
 * - state 狀態
 *   - 0: 棄用
 *   - 1: 正常
 * @returns
 */
export async function updateReceiveState(data) {
  const { id, state } = data
  return request({
    url: `/contractGoodsUsages/${id}`,
    method: 'patch',
    data: { state },
  })
}

/**
 * 取得指定(姓名、卡號、手機號碼)與店家與時間範圍的量測資料
 * @param {*} params
 * @returns
 */
export async function getStoreCaseRecord(params) {
  const { store_id } = params
  const searchParams = { [params.searchItem]: params.searchValue }
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    searchParams.startTime = new Date(params.date[0]).getTime() / 1000
    searchParams.endTime = parseInt(
      new Date(params.date[1].endOf('day')).getTime() / 1000
    )
  } else {
    searchParams.startTime = '1609430400'
    searchParams.endTime = '4102329600'
  }

  return request({
    url: `/measurements/location/${store_id}/case`,
    method: 'get',
    params: { ...searchParams, responseFormat: 'records' },
  })
}

/**
 * 以個案管理常用搜尋取得個案與量測資料
 * @param {*} params
 * @returns
 */
export async function getCaseByRules(params) {
  const date = {}
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    date.startTime = new Date(params.date[0]).getTime()
    date.endTime = new Date(params.date[1].endOf('day')).getTime()
  }
  return request({
    url: '/members/rule/query',
    method: 'post',
    data: {
      ruleId: params.caseSettingId,
      ...date,
    },
  })
}
