/**
 * @author chuzhixin 1204505056@qq.com
 * @description 登入、獲取用户訊息、退出登入、清除accessToken邏輯，不建議修改
 */
import { getUserInfo, login, logout } from '@/api/user'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken'
import { title, tokenName } from '@/config'
import { message, notification } from 'ant-design-vue'

const state = {
  accessToken: getAccessToken(),
  username: '',
  avatar: '',
  userInfo: {},
}
const getters = {
  accessToken: (state) => state.accessToken,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
  userInfo: (state) => state.userInfo,
}
const mutations = {
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 設置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 設置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 設置頭像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar
  },

  setUserInfo(state, payload) {
    state.userInfo = payload
  },
}
const actions = {
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 登入攔截放行時，設置虛擬角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    commit('setUsername', 'admin(未開啟登入攔截)')
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 登入
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    const { data, status } = await login(userInfo)
    const accessToken = data[tokenName]
    if (status == 200 && data.found) {
      commit('setAccessToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      notification.open({
        message: `歡迎登入${title}`,
        description: `${thisTime}！`,
      })
    } else {
      notification['error']({
        key: 'loginError',
        message: `帳號或密碼錯誤！`,
      })
    }
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 獲取用户訊息接口 這個接口非常非常重要，如果沒有明確底層前邏輯禁止修改此方法，錯誤的修改可能造成整個框架無法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, dispatch, state }) {
    const { data, status } = await getUserInfo(state.accessToken)
    if (status !== 200) {
      message.error(`驗證失敗，請重新登入...`)
      await dispatch('resetAll')
    }
    commit('setUserInfo', data)
    let { name, avatar = '', roles = ['user'], ability = [] } = data
    if (name && roles && Array.isArray(roles)) {
      if (roles.length == 0) roles = ['user']
      dispatch('acl/setRole', roles, { root: true })
      if (ability && ability.length > 0)
        dispatch('acl/setAbility', ability, { root: true })
      commit('setUsername', name)
      commit('setAvatar', avatar)
    } else {
      message.error('使用者訊息接口異常')
      await dispatch('resetAll')
    }
  },

  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 退出登入
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    await logout(state.accessToken)
    await dispatch('resetAll')
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ dispatch }) {
    await dispatch('setAccessToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setAbility', [], { root: true })
    removeAccessToken()
  },
  /**
   * @author chuzhixin 1204505056@qq.com
   * @description 設置token
   */
  setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },
}
export default { state, getters, mutations, actions }
