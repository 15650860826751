import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App'
import router from './router'
import store from './store'

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components'
import { LineChart, BarChart, PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  BarChart,
  PieChart,
  CanvasRenderer,
])

import 'ant-design-vue/dist/antd.less'
import '@/vab/styles/theme.less'
import '@/vab'
import SvgIcon from '@/components/SvgIcon'
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
const req = require.context('@/assets/icon', true, /\.svg$/)
requireAll(req)

// 使用mock server如果要讀取正式API請mark下列兩行
// const { mockXHR } = require('@/utils/static')
// mockXHR()

createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .component('icon', SvgIcon)
  .component('v-chart', ECharts)
  .mount('#app')
