import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
]
export const asyncRoutes = [
  // {
  //   path: '/accountManagement',
  //   component: Layout,
  //   redirect: '/accountManagement',
  //   meta: {
  //     title: '會員管理',
  //     icon: 'image-line',
  //     affix: true,
  //   },
  //   children: [
  //     {
  //       path: 'accountManagement',
  //       name: 'AccountManagement',
  //       component: () => import('@/views/accountManagement'),
  //       meta: {
  //         title: '會員管理',
  //         icon: 'user-line',
  //         affix: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/caseManagement',
    alwaysShow: true,
    meta: {
      title: '健康服務管理',
      icon: 'heart-line',
    },
    children: [
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard',
      //   component: () => import('@/views/healthManagement/dashboard'),
      //   meta: {
      //     title: '健康服務首頁',
      //   },
      // },
      // {
      //   path: 'servicePlanManagement',
      //   name: 'ServicePlanManagement',
      //   component: () =>
      //     import('@/views/healthManagement/servicePlanManagement'),
      //   meta: {
      //     title: '服務方案管理',
      //   },
      // },
      {
        path: 'purchaseServicePlan/:memberID(\\d+)',
        name: 'purchaseServicePlanMember',
        component: () =>
          import(
            '@/views/healthManagement/servicePlanManagement/purchaseServicePlan/_memberID'
          ),
        meta: {
          tagHidden: true,
        },
        hidden: true,
      },
      {
        path: 'caseManagement',
        name: 'CaseManagement',
        component: () => import('@/views/healthManagement/caseManagement'),
        meta: {
          title: '個案管理',
        },
      },
      {
        path: 'caseManagement/:_caseNo(\\d+)',
        name: 'CaseManagementDetail',
        component: () =>
          import('@/views/healthManagement/caseManagement/caseDetail/_caseNo'),
        meta: {
          title: '個案詳細資料',
          tagHidden: true,
        },
        hidden: true,
      },
      // {
      //   path: 'healthReport',
      //   name: 'HealthReport',
      //   component: () => import('@/views/healthManagement/healthReport'),
      //   meta: {
      //     title: '健康報告',
      //   },
      // },
      {
        path: 'healthRecord',
        name: 'HealthRecord',
        component: () => import('@/views/healthManagement/healthRecord'),
        meta: {
          title: '健康紀錄',
        },
      },
      {
        path: 'healthRecord/:recordNo(\\d+)',
        name: 'HealthRecordDetail',
        component: () =>
          import('@/views/healthManagement/healthRecord/_recordNo'),
        meta: {
          title: '健康紀錄詳細資料',
          tagHidden: true,
        },
        hidden: true,
      },
      {
        path: ':catchAll(.*)',
        name: 'Error404',
        component: () => import('@/views/404'),
        hidden: true,
      },
    ],
  },
  {
    path: '/operationReport',
    component: Layout,
    redirect: '/report',
    alwaysShow: true,
    meta: {
      title: '營運報表',
      icon: 'newspaper-line',
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/operationReport/index'),
        meta: {
          title: '營運數據首頁',
        },
      },
    ],
  },
  {
    path: '/setup',
    component: Layout,
    redirect: '/setup',
    alwaysShow: true,
    meta: {
      title: '設定',
      icon: 'settings-5-line',
    },
    children: [
      {
        path: 'searchBasicSetting',
        name: 'searchBasicSetting',
        component: () => import('@/views/setting/searchBasicSetting'),
        meta: {
          title: '健康狀態標準值設定',
        },
      },
      // {
      //   path: 'searchAdvancedSetting',
      //   name: 'searchAdvancedSetting',
      //   component: () => import('@/views/setting/searchAdvancedSetting'),
      //   meta: {
      //     title: '健康記錄進階常用搜尋設定',
      //   },
      // },
      {
        path: 'searchSetting/:type/:id(-?\\d+)',
        name: 'searchBasicDetail',
        component: () => import('@/views/setting/_id'),
        meta: {
          title: '健康記錄基本常用搜尋設定',
          tagHidden: true,
        },
        hidden: true,
      },
      {
        path: 'station',
        name: 'station',
        component: () => import('@/views/setting/station'),
        meta: {
          title: '健康小站管理',
        },
      },
      {
        path: 'station/:id(-?\\w+)',
        name: 'stationDetail',
        component: () => import('@/views/setting/station/_id'),
        meta: {
          title: '健康記錄基本常用搜尋設定',
          tagHidden: true,
        },
        hidden: true,
      },
      // {
      //   path: 'setup',
      //   name: 'setup',
      //   component: () => import('@/views/healthManagement/dashboard'),
      //   meta: {
      //     title: '健康項目標準值設定',
      //   },
      // },
      // {
      //   path: 'purchaseServicePlan/:memberID(\\d+)',
      //   name: '購買服務方案',
      //   component: () =>
      //     import(
      //       '@/views/healthManagement/servicePlanManagement/purchaseServicePlan/_memberID'
      //     ),
      //   meta: {
      //     tagHidden: true,
      //   },
      //   hidden: true,
      // },
    ],
  },
  // {
  //   path: '/index-old',
  //   component: Layout,
  //   redirect: '/index-old',
  //   meta: {
  //     title: '首页',
  //     icon: 'home-4-line',
  //     //affix: true,
  //   },
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'Index',
  //       component: () => import('@/views/index'),
  //       meta: {
  //         title: '首页',
  //         icon: 'home-4-line',
  //         //affix: true,
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/vab',
  //   component: Layout,
  //   redirect: '/vab/table',
  //   alwaysShow: true,
  //   meta: {
  //     title: '组件',
  //     icon: 'apps-line',
  //   },
  //   children: [
  //     {
  //       path: 'table',
  //       name: 'Table',
  //       component: () => import('@/views/vab/table'),
  //       meta: {
  //         title: '表格',
  //         icon: 'table-2',
  //       },
  //     },
  //     {
  //       path: 'icon',
  //       name: 'Icon',
  //       component: () => import('@/views/vab/icon'),
  //       meta: {
  //         title: '图标',
  //         icon: 'remixicon-line',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/test',
  //   component: Layout,
  //   redirect: '/test/test',
  //   meta: {
  //     title: '动态路由测试',
  //     icon: 'test-tube-line',
  //   },
  //   children: [
  //     {
  //       path: 'test',
  //       name: 'Test',
  //       component: () => import('@/views/test'),
  //       meta: {
  //         title: '动态路由测试',
  //         icon: 'test-tube-line',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/error',
  //   name: 'Error',
  //   component: Layout,
  //   redirect: '/error/403',
  //   meta: {
  //     title: '错误页',
  //     icon: 'error-warning-line',
  //   },
  //   children: [
  //     {
  //       path: '403',
  //       name: 'Error403',
  //       component: () => import('@/views/403'),
  //       meta: {
  //         title: '403',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //     {
  //       path: '404',
  //       name: 'Error404',
  //       component: () => import('@/views/404'),
  //       meta: {
  //         title: '404',
  //         icon: 'error-warning-line',
  //       },
  //     },
  //   ],
  // },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
})

export default router
