/**
 * @author cwhuang
 * @description 個案相關
 */
import { getContracts } from '@/api/case'

const state = {
  CasesDetail: [], // 個案詳細資料(搜尋後存放，避免重複查詢)
}
const getters = {
  caseInfo: (state) => state.caseInfo,
}
const mutations = {
  /**
   * @author cwhuang
   * @description 存入casesDetal
   * @param {*} state
   * @param {*} payload
   */
  setCasesDetail(state, payload) {
    state.CasesDetail.push(payload)
  },
}
const actions = {
  /**
   * @author cwhuang
   * @description 取得個案資料
   * @param {*} { commit, state }
   */
  async getCaseInfo({ commit, state }, params) {
    const hasData = state.CasesDetail.find((item) => {
      return item.memberID == params.memberID
    })
    if (!hasData) {
      const { data } = await getContracts({
        memberID: params.memberID,
      })
      commit('setCasesDetail', {
        memberID: params.memberID,
        data: data.result,
      })
      return data.result
    } else {
      return hasData.data
    }
  },
}
export default { state, getters, mutations, actions }
