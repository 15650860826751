import request from '@/utils/request'

/**
 * 搜尋會員.
 * @param {*} data
 * @returns
 */
export async function search(data) {
  return request({
    url: '/members',
    method: 'get',
    params: { [data.searchItem]: data.searchValue },
  })
}

export async function getCustInfo(data) {
  return request({
    url: '/members',
    method: 'get',
    params: { [data.searchItem]: data.searchValue },
  })
}
