/**
 * @author cwhuang
 * @description 常用搜尋設定
 */
import {
  searchSettingList,
  searchSettingListName,
  removeSettingList,
  newBasicSetting,
  saveBasicSetting,
  saveSetting,
  getSettingDetail,
} from '@/api/searchSetting'

const emptyRule = {
  type: '',
  item: '',
  operatorItem: '',
  component: [],
  operantItem1: '',
  operantItem2: '',
  operantItem3: '',
  sets: 'and',
}

const settingDetail = {
  title: '',
  staffId: '',
  created_time: '',
  editor: '',
  edit_time: '',
}

// 健康記錄常用搜尋schema
let schema = [
  {
    type: 'bloodPressure',
    name: '血壓',
    items: [
      {
        subject: 'systolic',
        name: '收縮壓',
        valueType: 'number',
      },
      {
        subject: 'diastolic',
        name: '舒張壓',
        valueType: 'number',
      },
      {
        subject: 'pulse',
        name: '脈搏',
        valueType: 'number',
      },
      {
        subject: 'arr',
        name: '心律不整',
        valueType: 'boolean',
      },
      {
        subject: 'pc',
        name: '早期收縮',
        valueType: 'boolean',
      },
      {
        subject: 'afib',
        name: '心房顫動',
        valueType: 'boolean',
      },
      {
        subject: 'ihb',
        name: '不規則心跳',
        valueType: 'boolean',
      },
      {
        subject: 'pulsePressureDifference',
        name: '脈壓差',
        valueType: 'number',
      },
    ],
  },
  {
    type: 'weight',
    name: '身體組成',
    items: [
      {
        subject: 'weight',
        name: '體重',
        valueType: 'number',
      },
      {
        subject: 'bmi',
        name: 'BMI',
        valueType: 'number',
      },
      {
        subject: 'bmr',
        name: '基礎代謝率',
        valueType: 'number',
      },
      {
        subject: 'body_fat',
        name: '脂肪率',
        valueType: 'number',
      },
      {
        subject: 'muscle_mass',
        name: '肌肉量',
        valueType: 'number',
      },
      {
        subject: 'visceral_fat',
        name: '內臟脂肪',
        valueType: 'number',
      },
    ],
  },
  {
    type: 'SpO2',
    name: '血氧',
    items: [
      // {
      //   subject: 'act',
      //   name: '量測期間最高 Artery Check Level',
      //   valueType: 'number',
      // },
      // {
      //   subject: 'pulse_highest',
      //   name: '量測期間最高脈膊',
      //   valueType: 'number',
      // },
      // {
      //   subject: 'pulse_lowest',
      //   name: '量測期間最低脈膊',
      //   valueType: 'number',
      // },
      // {
      //   subject: 'spo2_highest',
      //   name: '量測期間血氧最高值',
      //   valueType: 'number',
      // },
      {
        subject: 'spo2_lowest',
        name: '量測期間血氧最低值',
        valueType: 'number',
      },
    ],
  },
  {
    type: 'bodyTemperature',
    name: '體溫',
    items: [
      {
        subject: 'temperature',
        name: '體溫',
        valueType: 'number',
      },
    ],
  },
  {
    type: 'bloodGlucose',
    name: '血糖',
    items: [
      {
        subject: 'bloodGlucose',
        name: '血糖',
        valueType: 'number',
      },
      {
        subject: 'meal',
        name: '用餐時間',
        valueType: 'list',
        list: [
          {
            name: '空腹',
            value: 'fasting',
          },
          {
            name: '餐前',
            value: 'before_meal',
          },
          {
            name: '餐後',
            value: 'after_meal',
          },
        ],
      },
    ],
  },
]

const state = {
  search: { itemType: '' },
  settingList: [],
  settingDetail: {},
  advancedSetting: [],
  advancedSettingDetail: {},
  schema: [],
  subRules: [],
  rules: [{ ...emptyRule }],
}
const getters = {
  search: (state) => state.search,
  settingList: (state) => state.settingList,
  settingDetail: (state) => state.settingDetail, // 設定常用搜尋
  schema: (state) => state.schema,
  subRules: (state) => state.subRules,
  rules: (state) => state.rules,
  checkBasicSave: (state) => {
    if (
      state.settingDetail.title.length == 0 ||
      state.settingDetail.title.length > 48 ||
      state.settingDetail.type === undefined
    )
      return false

    let pass = true
    // 1.檢查所有勾選的項目都有填入值
    state.subRules.map((item) => {
      if (item.checked) {
        if (item.operantItem1 === undefined || item.operantItem1 === '') {
          pass = pass && false
        }
      }
    })

    // 2.檢查至少有一個被勾選
    const checked = state.subRules.some(function (item) {
      return item.checked
    })
    pass = pass && checked
    return pass
  },
  checkSave: (state) => {
    if (state.settingDetail.title === '') return false
    let pass = true
    state.subRules.map((item) => {
      if (item.type !== 'BasicSearch' && item.operantItem1 === '') {
        pass = pass && false
      }

      // 基本搜尋
      if (item.type === 'BasicSearch' && item.item === '') {
        pass = pass && false
      }
    })
    return pass
  },
  settingCaseList: (state) => state.settingCaseList,
}
const mutations = {
  setSettingList(state, payload) {
    state.settingList = payload
  },

  settingDetail(state, payload) {
    state.settingDetail = payload
  },

  setSubRules(state, payload) {
    state.subRules = payload
  },

  setRules(state, payload) {
    state.rules = payload
  },

  setSchema(state, payload) {
    state.schema = payload
  },

  setSettingCaseList(state, payload) {
    state.settingCaseList = payload
  },
}
const actions = {
  // 設定健康記錄量測子項目條件，當量測項目被修改時觸發
  setSubRules({ commit, state }, type) {
    const items = []
    let selectSchema = []
    switch (type) {
      case 'basic':
        selectSchema = [...schema]
        break
    }
    const schemaItems = selectSchema.find(
      (item) => item.type === state.settingDetail.type
    ).items

    schemaItems.map((item) => {
      items.push({
        ...item,
        checked: false,
        sets: 'and',
        operatorItem: 'eq',
      })
    })
    commit('setSubRules', [...items])
  },

  // 取得健康記錄常用搜尋
  async getSettingList({ commit }, params) {
    if (
      typeof params.searchString != 'undefined' &&
      params.searchString != ''
    ) {
      const { data } = await searchSettingListName(params)
      if (params.itemType != '') {
        commit(
          'setSettingList',
          data.result.filter((item) => item.type === params.itemType)
        )
      } else {
        commit('setSettingList', data.result)
      }
    } else {
      const { data } = await searchSettingList(params)
      if (params.itemType != '') {
        commit(
          'setSettingList',
          data.result.filter((item) => item.type === params.itemType)
        )
      } else {
        commit('setSettingList', data.result)
      }
    }
  },

  // 刪除指定的常用搜尋
  async removeSettingList({ commit, state }, params) {
    const { status } = await removeSettingList(params)
    if (status == 200) {
      const { settingList } = state
      commit(
        'setSettingList',
        settingList.filter((item) => item.id !== params.id)
      )
    }
  },

  setSettingList({ commit }, data) {
    commit('setSettingList', data)
  },

  // 新增與修改常用搜尋
  async saveBasicSetting({ commit, state }, params) {
    const { id, title, staffId, type, clauses } = params
    if (params.id == '-1') {
      const { status } = await newBasicSetting({
        title,
        staffId,
        type,
        clauses,
      })
      if (status == 200) {
        const { data } = await searchSettingList({
          type: 'basic',
          searchString: state.searchString,
        })
        commit('setSettingList', data.result)
      }
      return status
    } else {
      const { status } = await saveBasicSetting({
        id,
        title,
        staffId,
        type,
        clauses,
      })
      if (status == 200) {
        const { data } = await searchSettingList({
          type: 'basic',
          searchString: state.searchString,
        })
        commit('setSettingList', data.result)
      }
      return status
    }
  },
  async saveSetting({ commit }, params) {
    const { code } = await saveSetting(params)
    if (code == 200) {
      const { data } = await searchSettingList({})
      commit('setSettingList', data)
    }
  },

  // 取得常用查詢詳細資料
  async getBasicSettingDetail({ commit, dispatch, state }, params) {
    const { data } = await searchSettingList({ id: params.id })
    const filterData = data.result.find((item) => {
      return item.id == params.id
    })

    commit('settingDetail', filterData)
    dispatch('setSubRules', params.type)
    const Rules = state.subRules
    // 各項設定值寫入預設項目內
    for (const settingItem of filterData.clauses) {
      const index = Rules.findIndex((item) => {
        return settingItem.subject == item.subject
      })
      Rules[index].checked = true
      Rules[index].operatorItem = settingItem.predicate
      if (settingItem.predicate == 'bw') {
        Rules[index].operantItem1 = settingItem.component[0]
        if (settingItem.component.length >= 2) {
          Rules[index].operantItem2 = settingItem.component[1]
        }
        if (settingItem.component.length >= 3) {
          Rules[index].operantItem3 = settingItem.component[2]
        }
        if (typeof settingItem.sets !== 'undefined') {
          Rules[index].sets = settingItem.sets
        }
      } else {
        Rules[index].operantItem1 = settingItem.component
      }
    }
    commit('setSubRules', Rules)
  },
  async getSettingDetail({ commit }, params) {
    const { data } = await getSettingDetail(params)
    commit('settingDetail', data)
    commit('setRules', data.rules)
  },

  // 初始化基本條件資料
  initDetail({ commit }) {
    commit('settingDetail', { ...settingDetail })
    commit('setSubRules', [])
    commit('setRules', [{ ...emptyRule }])
  },

  // 初始化條件
  initRules({ commit }, type) {
    commit('setRules', [{ ...emptyRule, type }])
  },

  // 清除運算條件
  clearOperator({ commit, state }, index) {
    const rules = state.rules
    rules[index].operatorItem = ''
    rules[index].operantItem1 = ''
    rules[index].operantItem2 = ''
    rules[index].operantItem3 = ''
    commit('setRules', rules)
  },

  // 清除項目
  clearRuleItem({ commit, state, dispatch }, index) {
    const rules = state.rules
    rules[index].item = ''
    commit('setRules', rules)
    dispatch('clearOperator', index)
  },

  // 新增條件
  addRule({ commit, state }) {
    const rules = state.rules
    rules.push({ ...emptyRule, type: rules[0].type })
    commit('setRules', rules)
  },

  // 刪除條件
  delRule({ commit, state }, index) {
    const rules = state.rules
    rules.splice(index, 1)
    if (rules.length == 0) {
      commit('setRules', [emptyRule])
    } else {
      commit('setRules', rules)
    }
  },

  // 初始化schema
  async initSchema({ commit }, type) {
    let newSchema = []
    switch (type) {
      case 'basic':
        commit('setSchema', schema)
        break
      case 'advanced': {
        const { data } = await searchSettingList({ type: 'basic' })
        newSchema = [
          {
            type: 'BasicSearch',
            name: '基本搜尋',
            items: data,
          },
          ...schema,
        ]
        commit('setSchema', newSchema)
        break
      }
    }
  },
}
export default { state, getters, mutations, actions }
