<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>
<script>
  import zhCN from 'ant-design-vue/es/locale/zh_TW'
  export default {
    data() {
      return {
        locale: zhCN,
      }
    },
  }
</script>
<style lang="less">
  @import '~@/vab/styles/vab.less';
</style>
