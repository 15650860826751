import request from '@/utils/request'

export async function searchSettingList(data) {
  return request({
    url: '/searchRules',
    method: 'get',
    data,
  })
}
export async function searchSettingListName(data) {
  return request({
    url: `/searchRules/name/${data.searchString}`,
    method: 'get',
  })
}

export async function removeSettingList(data) {
  const { id } = data
  return request({
    url: `/searchRules/${id}`,
    method: 'delete',
    data,
  })
}

export async function newBasicSetting(data) {
  return request({
    url: '/searchRules',
    method: 'post',
    data,
  })
}

export async function saveBasicSetting(data) {
  return request({
    url: '/searchRules',
    method: 'patch',
    data,
  })
}

export async function saveSetting(data) {
  return request({
    url: '/saveSetting',
    method: 'post',
    data,
  })
}

export async function getBasicSettingDetail(params) {
  return request({
    url: '/getBasicSettingDetail',
    method: 'get',
    params,
  })
}

export async function getSettingDetail(params) {
  return request({
    url: '/getSettingDetail',
    method: 'get',
    params,
  })
}
