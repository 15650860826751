import request from '@/utils/request'

/**
 * 取得健康方案
 * @param string params
 */
export async function healthCases() {
  return request({
    url: '/healthCases',
    method: 'get',
  })
}

/**
 * 新建健康管理合約
 * @param {*} data
 * @returns
 */
export async function buyPlan(data) {
  return request({
    url: '/contracts',
    method: 'post',
    data,
  })
}

/**
 * 取得指定(姓名、卡號、手機號碼)與店家與時間範圍的量測資料
 * @param {*} params
 * @returns
 */
export async function getStoreRecord(params) {
  const { store_id } = params
  const searchParams = { [params.searchItem]: params.searchValue }
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    searchParams.startTime = new Date(params.date[0]).getTime() / 1000
    searchParams.endTime = parseInt(
      new Date(params.date[1].endOf('day')).getTime() / 1000
    )
  } else {
    searchParams.startTime = '1609430400'
    searchParams.endTime = '4102329600'
  }
  if (params.responseFormat) searchParams.responseFormat = params.responseFormat

  return request({
    url: `/measurements/location/${store_id}/member`,
    method: 'get',
    params: { ...searchParams },
  })
}

/**
 * 取得指定(姓名、卡號、手機號碼)與門市分區與時間範圍的量測資料
 * @param {*} params
 * @returns
 */
export async function getAreaRecord(params) {
  const { area_id, area_sub_id } = params.store
  const searchParams = { [params.searchItem]: params.searchValue }
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    searchParams.startTime = new Date(params.date[0]).getTime() / 1000
    searchParams.endTime = parseInt(
      new Date(params.date[1].endOf('day')).getTime() / 1000
    )
  }

  let areaQueryString = area_id
  if (area_sub_id !== '') areaQueryString += `-${area_sub_id}`

  return request({
    url: `/measurements/areas/${areaQueryString}/member`,
    // url: `/measurements/areas/11-20/member`,
    method: 'get',
    params: searchParams,
  })
}

/**
 * 以會員編號和時間區域取得量測資料
 * @param {*} params
 * @returns
 */
export async function getMemberRecord(params) {
  const searchParams = {}
  const headers = {}
  if (params.responseFormat != '') {
    headers.responseFormat = params.responseFormat
  }
  searchParams.startTime = new Date(params.date[0]).getTime() / 1000
  searchParams.endTime = parseInt(
    parseInt(new Date(params.date[1].endOf('day')).getTime() / 1000)
  )

  return request({
    // url: `/measurements/member/4010390/timeRange/1617206400/1629820800`,
    url: `/measurements/member/${params.memberID}/timeRange/${searchParams.startTime}/${searchParams.endTime}`,
    method: 'get',
    headers,
  })
}

/**
 * 以常用搜尋取得量測資料
 * @param {*} params
 * @returns
 */
export async function getRecordByRules(params) {
  const date = {}
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    date.startTime = new Date(params.date[0]).getTime()
    date.endTime = new Date(params.date[1].endOf('day')).getTime()
  }
  return request({
    url: '/measurements/rules/post',
    method: 'post',
    data: {
      type: params.type,
      clauses: params.clauses,
      ...date,
    },
  })
}

export async function getRecord(data) {
  return request({
    url: '/healthy/getRecord',
    method: 'post',
    data,
  })
}

export async function getRecordDetail(params) {
  const { id } = params
  return request({
    url: `measurements/records/${id}`,
    method: 'get',
  })
}

/**
 * 以健康方案啟用碼查詢相關資訊
 * @param string params
 */
export async function checkHealthyCode(params) {
  return request({
    url: '/purchasedProgramInfoByCode',
    method: 'get',
    params,
  })
}

/**
 * 取得指定店家指定時間範圍有進行量測的會員列表及其量測結果
 * @param string params
 */
export async function getStoreMemberRecordTime(params) {
  const date = {}
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    date.startTime = new Date(params.date[0]).getTime()
    date.endTime = new Date(params.date[1].endOf('day')).getTime()
  }
  return request({
    url: `/members/location/${params.store_id}/measurement/${date.startTime}/${date.endTime}`,
    method: 'get',
  })
}

/**
 * 取得指定多筆店家及時間範圍的會員量測資料
 *
 */
export async function getStoresMemberRecordTime(params) {
  const data = {}
  if (typeof params.date !== 'undefined' && params.date.length > 0) {
    data.startTime = new Date(params.date[0]).getTime() / 1000
    data.endTime = parseInt(
      new Date(params.date[1].endOf('day')).getTime() / 1000
    )
  }
  data.locationCodes = params.store_id
  data.responseFormat = params.responseFormat
  return request({
    url: `/measurements/query`,
    method: 'post',
    data,
  })
}
