import { render } from "./SvgIcon.vue?vue&type=template&id=dc49ef06&scoped=true"
import script from "./SvgIcon.vue?vue&type=script&lang=js"
export * from "./SvgIcon.vue?vue&type=script&lang=js"

import "./SvgIcon.vue?vue&type=style&index=0&id=dc49ef06&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-dc49ef06"
/* hot reload */
if (module.hot) {
  script.__hmrId = "dc49ef06"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('dc49ef06', script)) {
    api.reload('dc49ef06', script)
  }
  
  module.hot.accept("./SvgIcon.vue?vue&type=template&id=dc49ef06&scoped=true", () => {
    api.rerender('dc49ef06', render)
  })

}

script.__file = "src/components/SvgIcon.vue"

export default script